.tocModalIconHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 11px 6px 15px;
}
.countryBox{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #081E3F;
}
.dateBox{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #081E3F;
}
.event_type{
    margin: 0px 6px;
    padding: 8px 0px 7px 9px;
    background: #F1F2F2;
    display: flex;
    align-items: center;
}
.event_type .event_type_left{
    padding: 2px 12px 2px 14px;
    margin-right: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #081E3F;
    background: #FFCC00;
}
.event_type .event_type_right{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #081E3F;
}
.sub_event_typeInner{
    display: flex;
    align-items: center;
    padding: 5px 20px;
}
 .sub_event_typeLeft{
    /* padding: 3px 10px 4px 10px; */
    width: 52px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background: #E2E3E3;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: #081E3F;
}
.sub_event_typeRight{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    color: #000000;
}

.tocSipriHeader{
    padding: 6px 11px 6px 15px;
}
.tocSipriHeaderTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    line-height: 21px;
}
.money{
    font-size: 12px;
}

.tocSipriContent{
    padding: 0 5px 0 5px;
}

.tocSipriCountryIcon{
    display: flex;
    align-items: center;
    padding: 6px 0px 6px 25px;
}
.tocSipriCountryIcon:nth-child(odd){
    background: #F1F2F2;
}

.countryTotalBox{
    width: 52px;
    height: 28px;
    background: #E2E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    color: #081E3F;
}
.countryNameBox{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-left: 10px;
    color: #000000;
}


.lineChart{
    width: 96%;
    padding-top: 15px;
    margin: 0 2%;
    margin-top: 15px;
    border-top: solid 2px #000;
}
.lineChart p{
    margin: 0 0 8px 15px;
    padding: 0;
}