.dashboardMap-container{
    position:relative;
}

.left-bar{
    position:absolute;
    left: 9px;
    top:0;
    z-index:99;
    font-size: xx-large;
    color:#040E1D;
}

.right-bar{
    display: none; /* hidden right panel arrow  */
    position:absolute;
    right:9px;
    top:0;
    z-index:99;
    font-size: xx-large;
    color:#040E1D;
}