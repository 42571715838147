.slider_component .slider_year .ant-slider-handle {
    background: orange;
    border: none;
    width: 15px;
    height: 15px;
    margin-top: -6px;
    border-radius: 50%;
  }
  
  .slider_component .slider_year .ant-slider-track {
    background: orange !important;
  }
  
  .slider_component .slider_year .ant-slider-dot-active {
    background: orange !important;
  }
  
  .slider_component .slider_year .ant-slider-mark-text {
    display: inline-block;
    font-size: 12px;
    color: #000;
    writing-mode: tb-rl;
    transform: rotate(-90deg) !important;
    margin-top: -5px;
  }
  
  .slider_component .slider_year .ant-slider-mark-text:nth-child(odd) {
    margin-top: -36px;
  }
  .slider_top.slider_year{
    margin-top: 38px;
  }
  .slider_component .slider_top.slider_year .ant-slider-mark-text {
    display: inline-block;
    font-size: 12px;
    color: #000;
    writing-mode: tb-rl;
    transform: rotate(-180deg) !important;
    margin-top: -45px !important;
  }
  
  .slider_component .ant-slider-mark-text {
    display: inline-block;
    writing-mode: tb-rl;
    transform: rotate(180deg) !important;
    margin-left: -10px;
    font-size: 12px;
  }
  
  .slider_component .ant-slider-track {
    background: #000 !important;
  }
  
  .slider_component .ant-slider-handle-dragging, .slider_component .ant-slider-handledragging, .slider_component .ant-slider-handle-dragging {
    box-shadow: none !important;
    border: none !important;
  }
  
  .slider_component .ant-slider-handle {
    margin-top: -26px;
    border-radius: 0;
    background: orange;
    border: none;
    width: 12px;
    height: 50px;
    background: linear-gradient(0deg, transparent 15px, orange 0) bottom right, linear-gradient(0deg, transparent 15px, orange 0) bottom left, linear-gradient(135deg, transparent 15px, orange 0) top left, linear-gradient(-135deg, transparent 15px, orange 0) top right;
    background-size: 51% 51%;
    background-repeat: no-repeat;
  }
  
  .slider_component .ant-slider-dot-active {
    background: #000 !important;
  }
  
  .slider_component .ant-slider-dot {
    border: none;
    background: #ccc;
  }
  .slider_omit{
    width: 270px !important;
    padding-top: 20px;
  }
  .slider_omit .ant-slider-mark-text{
    padding-bottom: -10px;
  }