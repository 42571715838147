/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */

 ul div{
	 margin-top:1px;
	 margin-bottom:1px;
 }

 ul select{
	 height:24px;
 }

.calendarVFA{
	width:100px;
	margin-left:5px;
}

.IUULayerCheckboxPanel {
	width:100%;
	/* background: var(--yellow); */
}
.mapLayerTitle{
	background-color:#e9e9e9;
	padding:3px;
	border-bottom:1px solid #081e3f;
}
.mapLayerPanel{
	background-color: #fff;
    padding: 10px;
    font-size: 12px;
    font-family: Roboto;
}
.mapLayerPanel select{
	margin-left:5px;
}

.react-datepicker-wrapper
{
  width: auto !important;
}

#calIcon1, #calIcon2{
	margin-left:-15px;
	width:11px;
	height:13px;
	position: sticky;
}
 

.calendarFV #calendarFV .calendarVFA .calWrap
{
	width: 50px !important;
	background-color: green;
	padding: 80px;
	height:24px;
}

#calenderDivS{
    display:inline;
}

div[disabled]
{
	pointer-events: none;
	opacity: 0.7;
}