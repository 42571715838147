/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


.dash-article-sub-title{
  font-family: arial;
  font-weight: bold;
  font-size: 14px;
  color: #081e3f;
}

.dash-article-hr {
  margin: 0px;
  height: 4px;
  border: 0px solid #081e3f;
  background-color: #081e3f;    
}

.dash-item-div{
  display: flex;
  border-bottom: 1px solid #081e3f ;
  justify-content: center;
  align-items: center;
}

.dash-item-icon{
  flex: 10%;
  color: #ffcc00;
  padding: 0 5px;
}

.dash-item-icon img{
  width: 24px;
  height: auto;
  font-size: 20px;
}

.dash-item-text {
  flex: 90%;
  margin-left: 5px;
  line-height: 1;
  vertical-align: middle;
}

.dash-item-text span {
  font-family: arial;
  font-weight: normal;
  font-size: 10px;
  color: #081e3f;
}

.dash-more-link {
  font-family: arial;
  font-weight: bold;
  font-size: 12px;
  color: #081e3f;
}

.dash-article-search-holder{
  background-color: #fc0;
  padding: 6px;
}

.dash-article-input-holder{
  background-color: #fc0;
}

.dash-article-search-label{
  font-family: arial;
  font-weight: bold;
  font-size: 14px;
  color: #081e3f;
}

