.TOCLegendPanel{
    background-color:#fff;
	/* padding: 4px 0 0 0px; */
	font-size:12px;
    max-width: 129px;
}

/* .TOCLegend{
    padding-right: 6px;
} */

.legendTitle{
    padding: 4px 0 0 6px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height */


    color: #000000;

}

.legendContent{
    padding: 6px;
    max-height: 200px;
    overflow-y: auto;
}

.TOCLegendIcon{
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
}
.TOCLegendIcon >span{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
}
.TOCLegendIcon >img{
    margin-right: 5px;
}
/* ._SIPRILegendOne{
    border: 1px solid #000;
}
._GTDLegendOne{
    border: 1px solid #000;
} */

.LegendOne,.LegendTwo,.LegendThree,.LegendFour,.LegendFive,.LegendSix,
.GTDLegendOne,.GTDLegendTwo,.GTDLegendThree,.GTDLegendFour,.GTDLegendFive
{
    width: 18px;
    height: 11px;
    margin-right: 4px;
}
.countryLegendIcon{
    width: 18px;
    height: 11px;
    margin-right: 4px;
}
.acledLegend{
    width: 18px;
    height: 11px;
    margin-right: 4px;
}
.LegendOne {
    background: #F4F535;
}
.LegendTwo {
    background: #ECD37E;
}
.LegendThree {
    background: #F59126;
}
.LegendFour {
    background: #F46420;
}
.LegendFive {
    background: #F2331C;
}
.LegendSix {
    background: #EF0C1A;
}

.SIPRILegendOne {
    background: #FFFFFF;
}
.SIPRILegendTwo {
    background: #CADEEE;
}
.SIPRILegendThree {
    background: #9AB7D8;
}
.SIPRILegendFour {
    background: #8978AE;
}
.SIPRILegendFive {
    background: #853194;
}
.SIPRILegendSix {
    background: #4B064E;
}

.GTDLegendOne {
    background: #FFFFFF;
}
.GTDLegendTwo {
    background: #CADEEE;
}
.GTDLegendThree {
    background: #9AB7D8;
}
.GTDLegendFour {
    background: #8978AE;
}
.GTDLegendFive {
    background: #853194;
}


.TOCLegendDescribe{
    padding: 4px;
    padding-top: 0px;
}

.TOCLegendDescribeHR{
    border: 0.5px solid #000;
}

.TOCLegendDescribeContent{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 11px;
    padding-top: 4px;
    color: #000000;
}