/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */
 .dataBoxTitleContainer.layerContainer{
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: Roboto;
    font-style: normal;
 }
 .TOCLayerCheckboxPanel {
	width:100%;
	/* background: var(--yellow); */
}
.mapLayerTitle{
	padding:3px;
    /* background: #081E3F;
    opacity: 0.3; */
    background: rgb(185, 188, 194);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mapLayerTitleLeft{
    display: flex;
    align-items: center;
}

/* .mapLayerTitle .mapLayerTitleLeft >input[type="radio"]{
    background-color: #000!important;
    border-color: #000!important;
} */

.mapLayerTitleLeft .mapLayerTitleLeftRadio{
    margin-top: 4px;
    margin-right: 3px;
}
.mapLayerTitleLeft .mapLayerTitleLeftContent{
    font-weight: bold;
    font-size: 12px;
    /* line-height: 14px; */
    /* identical to box height */
    /* margin-top: 7px;
    margin-left: 4px; */

    color: #000000;
}
.mapLayerPanel{
	background-color:#fff;
	padding:15px 7px;
	font-size:12px;
    font-family: Roboto;
}
.mapLayerPanel select{
	margin-left:5px;
}

.chooseView{
    display: flex;
    justify-content: center;
    
}
/* MuiTypography-root MuiTypography-body1 */

.chooseView .MuiTypography-body1{
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */


    color: #000000;
}
.sliderBox{
    margin: 19px 9px 0px;
}

/* .sliderBox .ant-slider{
    margin: 22px auto 0px;
} */
.sliderBox .ant-slider-with-marks {
     margin-bottom: 20px
}
.mapLayerContentHr{
    margin: 19px 9px 5px;
    border: 0.5px solid #081E3F;
    border-bottom: none;
}
.mapLayerEventType{
    padding: 0 9px;
}
.mapLayerEventType .layerEventType{
    display: flex;
    align-items: center;
}
.mapLayerEventType .layerEventType >span{
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    margin-left: 5px;
}
.layerSubEventType{
    margin-left: 15px;
    margin-top: 10px;
}
.layerSubEventTypeIcon{
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */


    color: #000000; 
    margin: 5px 0;
}

.layerSubEventTypeIcon >span{
    margin-left: 5px;
}

.importOrExport{
    display: flex;
    justify-content: space-between;
    padding: 4px 10px 16px 16px;
}
.import>div,.export>div{
    margin-left: 5px;
}
.import{
    display: flex;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
}
.export{
    display: flex;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;

    color: #000000;
}
.money{
    font-weight: normal;
}

.mapLayerEventTypeHr{
    border: 0.5px solid #081E3F;
    margin: 5px 0px;
    border-bottom: none;
}

.mapLayerContent span.MuiSlider-markLabel{
    font-weight: normal;
    font-size: 12px;
    /* line-height: 14px; */
    /* identical to box height */
    color: #000000;
}
.mapLayerContent >.mapLayerTitle_Year{
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    margin-left: 6px;  
    color: #081E3F;
}
.mapLayerContentHr{
    border: none;
    border-bottom: solid 1.5px #081E3F;
}

.ant-radio-wrapper .ant-radio-inner {
    transform: scale(.8);
}

.ant-radio-wrapper .labelMark{
    font-size: 12px;
    font-weight: normal;
    position: relative;
    top: -2px;
}


.ant-checkbox-wrapper .ant-checkbox-inner {
    transform: scale(.6);
}

.ant-checkbox-wrapper .labelMark{
    font-size: 12px;
    font-weight: normal;
    position: relative;
    top: -2px;
}