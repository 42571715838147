 .highcharts-container {
    overflow: visible !important;
}
 .MyChartTooltip {
    position: relative;
    z-index: 50;
    border: 2px solid rgb(0, 108, 169);
    border-radius: 5px;
    background-color: #ffffff;
    padding: 5px;
    font-size: 9pt;
    overflow: auto;
    height: 180px;
}
.highcharts-tooltip {
  pointer-events: auto !important;
}

.highcharts-tooltip > span {
  max-height: 180px;
  overflow-y: auto;
}