.pointPopupBox{
    font-family: Roboto;
    font-style: normal;
    max-height: 260px;
    overflow: hidden;
    overflow-y: scroll;
}
.pointPopupHead{
    display: flex;
    align-items: center;
}
.pointPopupNum{
    background: #FFCC00;
    padding: 2px 10px 3px;
    margin-right: 8px;
}
.pointPopupTitle{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #081E3F;
}
.pointPopupConIcon{
    margin-top: 5px;
}
.contentIconTitle{
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #081E3F;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #081E3F
}
.contentIconTitleActive{
    background-color: #E2E3E3;
}

.contentIconTitleLeft{
    display: flex;
    align-items: center;
}
.contentIconTitleLeftNum{
    background-color: #E2E3E3;
    padding: 2px 10px;
    margin-right: 8px;
}


.contentIconContent .popupTable{
    width: 305px;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #081E3F;
    background-color: #F1F2F2;
}
.numThBox{
    width: 5%;
}

.dateThBox{
    width: 20%;
    font-size: 10px;
}
.locationThBox{
    width: 20%;
}
.notesThBox{
    width: 55%;
}



.contentIconContent table .notesBox{
    white-space: nowrap;  
    text-overflow: ellipsis; 
    overflow: hidden;      
}

.popupTooltip{
    position: absolute;
    left: 0;
    top: 10;
    background: #fff;
    padding: 5px;
}

.pointPopupBox::-webkit-scrollbar {
    display: none;
}

.pointPopupBox {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}