/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


.analytics-container {
    overflow: hidden;
    font-family: Roboto;
}

.stat-data {
    font-weight: bold;
    font-size: 22px;
    color: var(--blue);
    padding: 5px;
}
.stat-data-data {
     background: var(--yellow); 
     margin: 0px; 
     padding: 0px 5px; 
}
.stat-data2 {
    font-weight: bold;
    font-size: 22px;
    color: var(--white);
    padding: 0px;
    box-sizing: border-box;
}
.stat-data-data2 {
    background: var(--blue); 
    margin: 0px; 
    padding: 0px 5px;
    box-sizing: border-box;
    text-align: center;
}

.stat-label {
    font-family: Roboto;
    font-style: normal;
    font-size: 15px;
    vertical-align: middle;
    color: #081E3F;
    line-height: 1;
    font-weight: 600;
    margin-top: 15px;
    text-align: left;
}

.graph-title {
    background: #082448;
    color: var(--white);
    text-align: center;
    padding-left: 10px;
    margin: 10px 50px;
    padding: 5px;
    font-weight: 600;
    font-size: 15px;
}

.dashRHS-hr {
    margin: 0px;
    height: 4px;
    border: 0px solid #081e3f;
    background-color: #081e3f;
}

.dashRHS-hr2 {
    margin-top: .65rem;
    margin-bottom: .3rem;
    height: 2px;
    border: 0px solid #081e3f;
    background-color: #081e3f;
    opacity: 55%;
}

.dashboardLHS-container .dashRHS-hr {
    margin: 0px 10px;
}

.dataBoxContainer{
    margin-bottom:30px;
}

.dataBoxContainer .c3{
    max-height: 250px;
    position: relative;
    margin: 10px 0px;
    overflow-x: auto;
    width: 95%;
}

.ss-graph-title{
    position: absolute;
    bottom: 43%;
    left: 40%;
}
.fishery-graph-title{
    position: absolute;
    bottom: 43%;
    left: 40%;
}
.dataBoxTitleContainer {
    background: var(--yellow);
    color: var(--blue);
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    height: auto;
    /* padding: 8px; */
}

.iuu-sep {
    height: 4px;
    background: #081E3F;
    margin: 10px 2px;
}

.iuu-sep2 {
    height: 4px;
    background: #081E3F;
    margin: 10px 2px;
    
}

.iuu-sep3 {
    border: 1px solid #081E3F
}
.popupDiv
{
    background: #F1F2F2; 
    margin-top: 1rem
}
.popupTitle
{
    position: absolute;
    width: 61px;
    height: 16px;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    color:#010914;
}

.iuuLabel{
    padding-top:30%;
}

.urlContainer{
    font-size:12px;
    margin-bottom:10px;
}/*
.urlContainer2{
    font-size:12px;
    margin-bottom:10px;
}*/

.informationContainer{
    font-family: Roboto;
    font-style: normal;
    font-size: 10px;
    text-align: left;
    color:#010914;
}

.iuuModalIcon{
    cursor: pointer;
    text-align: center;
    display: block;
}

.iuuModalImage{
    text-align: center;
    display: block;
}

.iuuModalContainer{
    padding: 20px;
}

.fish-by-eez-image, .transshipment-image{
    width: 80%;
    height: auto;
    padding: 10px;
}

.iuuModalLink{
    font-size: 1rem;
}
/*
#NoData 
{
	height: 9em;
    margin-top: 1em;
    margin-left: 0em;
    transform: translate(0%, 30%);
}*/

#pieGraphTotal, #pieGraph1 ,#pieGraph2 ,#pieGraph3 
{
	
    margin-top: 1em;
  /*  margin-left: -4em;
  height: 9em;
    transform: translate(23.5%, 0%);*/
}
g.text-group
{
    background-color: red;
}