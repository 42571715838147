/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */

 @import url("https://api.mapbox.com/mapbox-gl-js/v2.5.0/mapbox-gl.css"); 

.dataLayerContiner{
	margin:0px;
}
 

.layerContainer{
	position: absolute;
    bottom: 40px;
	right: 30px;
	/* width: 50%; */
    width: auto;
	padding:0px;
}

.legendContainer{
	position: absolute;
	bottom: 40px;
	left: 30px;
	width: auto;
	padding: 0px;
}
.legendWhiteBox {
	padding: 10px;
	background-color: white;
}


.legendPanel{
	background-color:#fff;
	padding:25px;
	font-size:12px;
}
.fsiLegend{
	background: rgb(106,229,93);
	background: linear-gradient(90deg, rgba(106,229,93,1) 0%, rgba(255,192,56,1) 50%, rgba(255,56,56,1) 100%);
	height: 10px;
	width: 100%;
}

.pulseLegend
{
	background: linear-gradient(90deg,#ff0000,#1aff00);
	/*background: linear-gradient(90deg,#ff3838,#6ae55d);*/
    height: 10px;
    width: 100%;
}

.fsiLowLabel{
	padding-left: 5px;
}
.fsiHighLabel{
	padding-left:25px;
}
.aisLegend{
	background: rgb(68,1,84);
	background: linear-gradient(90deg, rgba(68,1,84,1) 0%, rgba(31,150,139,1) 50%, rgba(253,231,37,1) 100%);
	height:10px;
	width: 100%;
}
.aisLowLabel{
	padding-left: 5px;
}
.aisHighLabel{
	padding-left: 15px;
}


.legendTitle, .TransshippmentLabel, .FishingActivityLabel,.FishVisitLabel
{
	font-family: Roboto;
    font-style: normal;
    font-size: 12px;
	white-space: nowrap;
    justify-content: center;
	font-weight: 400;
}

.legendTitle{
	font-weight: 600;
}


.FishVisitLegend, .FishingActivityLegend, .TransshippmentLegend 
{
    border-radius: 100%;
    width: 10px;
    height: 10px;
	margin-left: auto;
	margin-right: auto;
}

.TransshippmentLegend 
{
    background: #00a819;
} 
.FishingActivityLegend 
{
    background: #0017b0;
	width: 5px;
    height: 5px;
}
.FishVisitLegend
{
	background: #ff0000;
}

.circleContainer{
	padding:5px;
}

.dataLayerTitle{
	padding:5px;
}

.layerContainer ul{
	padding-left: 10px;
    list-style: none;
}
.layerContainer li{
	border-bottom:1px solid #081e3f;
	padding:5px 0px;
}
.layerContainer li label{
	margin-bottom:0px;
	margin-left:10px;
}
.dislaimerIcon {
	cursor: pointer;
}

.mapboxgl-popup {
	max-width: 400px;
	font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
	}

.mapboxgl-popup-content
{
	width: 15rem;
}
/*
table {
	width: 80%;
  }
  td {
	padding-left: .3rem;
	padding-top: 0px;
	padding-bottom: 0px;
  }*/
  /*tr {
	padding-top: 0px;
	padding-bottom: 0px;
  }*/
/*
.marker {
	/*background-image: url('mapbox-icon.png');
	background-size: cover;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	cursor: pointer;
  }*/