.Radio_Box{
    position: relative;
    margin: 0;
}
.radio[type="radio"] {
    width: 14px;
    height: 14px;
    opacity: 0;
}

.label {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #000;
    margin: 0;
}

.radio:checked+.label {
    background-color: #000;
}

.radio:checked+.label::after {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    top: 3px;
    left: 3px;
    background-color: #fff;
    border-radius: 50%;
}