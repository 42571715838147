/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


a {
  font-family: arial;
  font-weight: bold;
  font-size: 12px;
  color: #081e3f;
}

a:hover{
  color: #ffcc00;
}

.dialog-text{
  color: #081e3f;
  font-family: arial;
  font-weight: bold;
}

.dialog-footer{
  margin: auto;
  padding: 0px 20px 20px;
  border: none;
}

.dialog-no-btn{
  background-color: #081e3f;
  color: white;
  font-weight: bold;
  font-size: 18px;
  min-width: 100px;
}
.dialog-no-btn:hover{
   /* hover effectively does nothing */
   color: white;
   background-color: #081e3f;
   border-color: none;  
}

.dialog-yes-btn{
  margin-left: 40px;
  background-color: #fc0;
  color: #081e3f;
  border-color: #081e3f;
  font-weight: bold;
  font-size: 18px;
  min-width: 100px;
}
.dialog-yes-btn:hover{
  /* hover effectively does nothing */
  color: #081e3f;
  background-color: #fc0;
  border-color: #081e3f;    
}