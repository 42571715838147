/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


 .news-container{
    height: 100%;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

}

.newsFilterBar{
    padding:0px 10px;
	padding-top:5px;
	height:auto;
	line-height:1;
}

.newsFilterBar div{
	border:0px;
}

.newsFilterBar [class*='ddl-']{
	border-bottom: 1px solid #081e3f!important;
	margin-left:10px;
}   

.newsFilter-reset-button{
	line-height:1!important;
	border-radius: 3px!important;
	margin-top:5px;
	margin-bottom:5px;
	color:white;
    font-family:arial;
	font-weight:bold;
	font-size:14px;
}

.newsFilterBar .btnSet {
    align-self: center;
}

.newsFilterBar .btnSet div{
	padding-right:10px;
	display:inline-flex;
}

.newsFilterBar .btnSet .iconContainer{
	margin-left:10px;
	height:100%;
}

.news-container > div {
    padding-bottom: 30px;
}

.newsCard{
    padding-top:10px;
    padding-bottom:10px;
}
.newsCardContainer{
    overflow-y: scroll;
    overflow-x: hidden;
    height:75vh;
    /*min-height:calc(100vh - 28px);*/
    /*width: 907px;*/
    /*width:200vh;*/

}
.newsCardTitle{
    position: relative;
    left: 1.29%;
    right: 86.87%;
    top: 79.08%;
    bottom: 18.4%;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;

    /* identical to box height */

    color: #081E3F;
    margin-bottom: 10px;
}

.newsCardLanguage{
    position: relative;
    left: 1.31%;
    right: 90.16%;
    top: 81.87%;
    bottom: 16.33%;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;

    color: #081E3F;
    padding-left: 3px;
}

.newsCardTopic{
    position: relative;
    left: 1.29%;
    right: 89.95%;
    top:95.82%;
    bottom: 12.26%;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    color: #081E3F;
}

.newsCardSource{
    position: relative;
    left: 1.29%;
    right: 86.25%;
    top: 88.34%;
    bottom: 9.75%;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    color: #081E3F;
}

.newsCardCountry{
    position: relative;
    left: 25.11%;
    right: 63.9%;
    top: -20%;
    bottom: 12.26%;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    color: #081E3F;
}

.newsCardDateTime{
    padding-left: 15px;
}

.newsCardLink{
    position: relative;
    left: 39.44%;
    right: 31.03%;
    top: -30%;
    bottom: 9.65%;
    width: 124px;
    height: 36px;
    background: #FFCC2F;
    padding:5px;
    font-family: Roboto;
}

.cardOdd{
    background: #F1F2F2;
}

.newsInfographContainer{
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 75vh;
}

.newsInfographContainer text{
    font-size: 13px;
    font-family: Roboto;
    font-weight: 200;
}

.newsInfographCountryLabel
{
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    margin:10px;
    /* identical to box height */
    color: #081E3F;
}

.inTitleTooltipIcon
{
    padding-left: 4px;
}

.IUU_score
{
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
   /* font-size: 16px;*/
    line-height: 21px;
    margin:10px;
    /* identical to box height */
    color: #081E3F;
    display: inline;
}


.newsInfographLabel{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 5px;
    text-align: center;

    /* identical to box height */

    color: #081E3F;
}

.newsInfographSep{
    height: 6px;
    width: auto;
    /*left: 943.4580078125px;
    top: 568.6019897460938px;*/
    border-radius: 0px;
    background: #081E3F;
}

.newsCardGrid{
    padding-left: 15px;
    padding-top: 10px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 200;
    font-size: 13px;
    line-height: 16px;

    color: #081E3F;
}

.newsCardGrid span{
    font-weight: 600;
}

.newsDashboardLink{
    position:relative;
    top:20px;
    left: 25px;
}

.loadMoreButton{
    background: #FFCC2F;
    padding-bottom: 20px!important;
}

.loadMoreButtonLabel{
    color:#081E3F;
    background: #FFCC2F;
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    font-style: normal;
}

.newsSourceLabelHyperion, .newsSourceLabelGDelt{
    position: relative;
    /* top:50px; */
    left: 5%;

    color:#081E3F;
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    font-style: normal;
}

.news-toggle-source-button{
	line-height:1!important;
	border-radius: 3px!important;
	margin-top:5px;
	margin-bottom:5px;
	color:white;
    font-weight: 700;
    font-size: 14px;
}

.news-toggle-source-button-active{
	line-height:1!important;
	border-radius: 3px!important;
	margin-top:5px;
	margin-bottom:5px;
	background: #FFCC00;
    color: #081e3f;
    font-weight: 700;
    font-size: 14px;
}

.news-toggle-source-button-active:focus{
	background: #FFCC00;
    color: #081e3f;
}