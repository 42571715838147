/*
 * NOTICE
 *
 * This software was produced for the U. S. Government under Basic Contract No. W56KGU-18-D-0004, and is subject to the
 * Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation Clause 252.227-7014
 * (FEB 2012)
 *
 * Copyright 2021 The MITRE Corporation.
 */


body{
	overflow:hidden;
	position:absolute;
	height:100%;
	width:100%;
	font-family:arial;
}

.heading3{
	font-family:arial;
	color:#081e3f;
	font-weight:bold;
	font-size:14px;
}

.headerRow{
	margin-right:0px;
}

.filtersBar{
	padding:0px 10px;
	padding-top:10px;
	height:auto;
	line-height:1;
}

.filterItem{
	display:inline-block;
	width:33%;
}

.filtersBar [class*='-indicatorSeparator']{
	width:0px!important;
}

.filtersBar [class*='-indicatorContainer']{
	padding:0px!important;
}

.filtersBar [class*='-ValueContainer']{
	padding-left:0px!important;
}

.filtersBar [class*='-control']{
	min-height:25px!important;
}

[class*='-control']:hover,[class*='-control']:focus, [class*='-control']:not( :hover ),.authContainer .btnSignin:focus, .authContainer .btnSignin:not( :hover ){
	border:0px!important;
	box-shadow:none;
}

.filtersBar div{
	border:0px;
}

.filtersBar [class*='ddl-']{
	border-bottom: 1px solid #081e3f!important;
	margin-left:10px;
}   

.filter-apply-button, .filter-reset-button{
	line-height:1!important;
	border-radius: 3px!important;
	margin-top:5px;
	margin-bottom:5px;
	color:white;
}

.filtersBar .btnSet div{
	padding-right:10px;
	display:inline-flex;
}

.filtersBar .btnSet .iconContainer{
	margin-left:10px;
	height:100%;
}

.logoContainer{
  padding-top: 2px;
    padding-left: 2px;
	height:65px;
}

.logoImg{
	height:60px;
}

.logoMobile{
	display:none;
}

.logoImg.resize{
  max-width: 100%;
  height: auto;
}

.dashboard-title {
    font-family: arial;
    font-weight: bold;
    font-size: 24px;
	padding-left: 0px;
    text-align: center;
	line-height:1.5;
	width:auto;
	display:inline-block;
	padding:0px 20px;
}

.dashboard-nav-link{
	color:white;
	font-family: arial;
	font-weight: bold;
	font-size: 24px;
	text-align: center;
}
.collaboration-nav-link{
	color:white;
	font-family: arial;
	font-weight: bold;
	font-size: 24px;
	text-align: center;
}

.user-management-button{
	
}

.headerRow-right{
	background-color: #081E3F;
}
.authContainer .btnSignin, .authContainer .btn{
	display: inline-block;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 0px;
    padding: 5px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 0;
	cursor:pointer;
}

.authContainer .btnSignin:hover{
	text-decoration:underline;
}

.user-management-button{
	cursor:pointer;
	margin-right:5px;
}

.backMainBar{
	padding: 5px;
	cursor:pointer;
	text-decoration:underline;
	font-weight:400;
	color: #FFFFFF;
}

.backMainBarLink{
	color: #FFFFFF;
}


.mainDashboard{
	position:absolute;
	top:128px;
	bottom:28px;
	overflow-y:hidden;
	overflow-x:hidden;
	width:100%;
}

.css-79elbk {
    position: relative;
    HEIGHT: 100%;
}

/* style for left infobox ###############*/


.dashRHS-hr {
  margin: 0px;
  height: 4px;
  border: 0px solid #081e3f;
  background-color: #081e3f;    
}

.dashboardLHS-container .dashRHS-hr {
    margin: 0px 10px;
}

.dataBoxContainer{
	margin-bottom:30px;
}

.dataBoxContainer .c3{
	max-height: 250px;
    position: relative;
    margin: 10px 0px;
    overflow-x: auto;
	width: 95%;
}

/* end style for left infobox ###############*/

/* Map Area Style ###########################*/

/* End of Map Area Style ###########################*/

.dashboardLHS-container, .dashboardMap-container, .dashboardRHS-container, .dashboardLHS, .dashboardMap,.mainDashboard-row, .mapContainer{
	height:100%;
}

.dashboardLHS-container, .dashboardMap-container, .dashboardRHS-container{
	padding:0px;
}

.dashboardRHS-container{
	padding-right:16px;
	overflow-y:auto;
}

.dashboardMap-container{
	padding-left:2px;
	padding-right:2px;
}

.dashboardLHS{
	overflow-y: auto;
    overflow-x: hidden;
	padding-left:16px;
}


.mapboxgl-ctrl-attrib-inner{
	font-size:12px;
}

.reference-url{
	padding-left:16px;
	padding-right:5px;
}



.mapboxgl-control-container{
	position: absolute;
    bottom: 0px;
}

.footer{
	position:absolute;
	bottom:0px;
	width:100%;
	font-weight:500;
	background-color:#e6e7e8;
}

.footerText{
	font-family:arial;
	font-size:12px;
	color:#081e3f;
	padding:5px;
	text-shadow: 0.5px 0.5px #eee;
	display:inline-block;
}

.footer .copyright{
	right:150px;
	position:absolute;
}
.footer .copyrightMobile{
	display:none;
}
.footerLeftBtn{
	cursor:pointer;
	text-decoration:underline;
}

.footerImg{
	height:20px;
	margin-right:10px;
	position:absolute;
	margin-top:4px;
	top:0px;
	right:10px;
}

.headerSeparator {
    width: 100%;
    height: 3px;
    background-color: #091e3f;
}

.c3-chart-arcs-title{
	font-size: 24px;
}

@media (max-width: 575.98px) {
	.heading3{
		font-size:12px;
	}
	.logoContainer{
		height:50px;
	}
	.logoImg{
		display:none
	}
	.logoMobile{
		display:block;
		height:50px;
	}
	.dashboard-title{
		line-height:1.3;
		font-size:20px;
	}
	.user-management-button{
		font-size:14px;
	}
	.btnSet{
		text-align:right;
	}
	.mainDashboard{
		top:123px;
	}
	.dataBoxContainer{
		margin-bottom:5px;
	}
	.dataBoxTitleContainer{
		font-size:13px;
		padding:3px;
	}
	.dataBoxTitleContainer .row{
		margin:0px;
	}
	.stat-data{
		font-size:20px;
	}
	.stat-label{
		font-size:18px;
	}
	.graph-title{
		margin:0px;
	}
	.dashboardLHS-container{
		height:150px;
	
	}
	.dashboardMap-container{
		height:220px;
	}
	.layerContainer{
		position:absolute;
		top:0px;
		left:0px;
		height:110px;
		width:100%;
	}
	.layerContainer .row{
		margin:0px;
	}
	.layerContainer .collapse{
		height:80px;
		overflow-y:auto;
	}

	.dashRHS-search-label{
		font-size:12px;
		margin:0px;
	}
	.dashRHS-input-holder .form-control{
		font-size:12px;
		height:auto;
	}
	.footer .copyright{
		display:none;
	}
	.footer .copyrightMobile{
		display:inline-block;
	}
}